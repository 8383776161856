import "./src/style/global.css"

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `The app was updated. ` +
      `Do you wan to get the latest version for the full experience?`
  )
  if (answer === true) {
    window.location.reload()
  }
}
