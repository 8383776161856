import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: [
      'IBM Plex Sans',
    ].join(','),
      h1: {
        fontSize: '2.98575rem',
      },
      h2: {
        fontSize: '2.4883125rem',
      },
      h3: {
        fontSize: '2.073625rem',
      },
      h4: {
        fontSize: '1.728rem',
      },
      h5: {
        fontSize: '1.4375rem',
      },
      h6: {
        fontSize: '1.1875rem',
      },
      body1: {
      fontSize: '1.1875rem',
      },
      body2: {
        fontSize: '1rem',
      },
      button: {
        fontSize: '1.1875rem',
        fontWeight: '600',
        textTransform: "none",
      },
  },
  overrides: {
    MuiPaper: {
      root: {
        boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
        borderRadius: 5,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 10,

      },
      contained: {
        backgroundColor: 'gradient(138.25deg, #FFAF75 7%, #F45F00 95.28%)',
      }
    }
  },
  palette: {
    primary: {
      main: '#8BD2C1',
      light: '#CBE4DE',
      // dark: '#001756',
      gray: '#D2D3D5',
      black: '#130D1F',
      contrastText: '#130D1F'
    },
    secondary: {
      main: '#F45F00',
      light: '#FFF',
      black: '#130D1F',
      dark: '#4B4F56',
      // gray: '#eee',
    },
    error: {
      main: red.A400,
      light: red.A100,
    },
    background: {
      default: '#fafafa',
      gray: '#eaeaea',
      // sectionLight: '#bcc3ff',
      // sectionDark: '#DDE1FF'
    },
    text: {
      dark: '#130D1F',
      light: '#fff',
      // variant: '#C5CAE9'  
  }
  },
});

export default theme;

